<template>
  <div v-if="user">
    <div class="mt-8 text-center text-h6" @click="go('Barcode')">會員條碼</div>

    <div class="mt-4 text-center text-h6">
      <QrcodeVue :value="userID" :size="100"></QrcodeVue>
      {{ userID }}
    </div>

    <div class="mt-8 text-center text-h6">
      目前{{ mainUnit.text }} {{ this.user.balance || 0 }} {{ mainUnit.unit }}
    </div>
    <div class="mt-0 text-center text-h6" v-if="this.user.ticket">
      目前彩票 {{ this.user.ticket || 0 }} 張
    </div>
    <div class="text-center warning--text" @click="getUser()">重新讀取</div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import login from "@/mixins/login";
const ballStores = ["@748tzjfx", "@437pthff"];

export default {
  name: "Qrcode",
  mixins: [login],
  components: { QrcodeVue },
  data: () => ({}),
  computed: {
    mainUnit() {
      switch (true) {
        case ballStores.includes(this.user.store):
          return { text: "鋼珠", unit: "顆" };
        default:
          return { text: "點數", unit: "點" };
      }
    },
  },
};
</script>
